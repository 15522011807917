import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["firstName", "lastName", "displayName"];

  connect() {
    // Set initial placeholder if no value
    if (!this.displayNameTarget.value) {
      this.updatePlaceholder();
    }
  }

  updatePlaceholder() {
    const firstName = this.firstNameTarget.value.trim();
    const lastName = this.lastNameTarget.value.trim();
    const fullName = [firstName, lastName].filter(Boolean).join(" ");

    if (fullName) {
      this.displayNameTarget.placeholder = fullName;
    }
  }
}
